/* global
 autosize
 bsCustomFileInput
 gettext
 checkRedirect
 initHtmlTextarea
 $x
 */


// #############################################################################
// GLOBAL VARS

const $body = $("body");

// #############################################################################
// BETTER FOCUS

const $betterFocus = new $x.BetterFocus();
$betterFocus.init();

// #############################################################################
// TOOLTIP

$("[data-toggle=tooltip]").tooltip();

// #############################################################################
// FORM: MULTIPLE SELECT ITEMS

$x.initMultipleSelectItems = () => {
  const $inputs = document.querySelectorAll("[data-multiple-select-item]");

  $inputs.forEach(function ($input) {
    if ($input.$multipleSelectItem) {
      return;
    }

    $input.$multipleSelectItem = new $x.MultipleSelectItems($input);
    $input.$multipleSelectItem.init();
  });
};

// #############################################################################
// FORM

$x.initFormDefaults = function ($parent = $body) {
  // File
  // TODO: Selber schreiben!
  bsCustomFileInput.init();

  // Autosize
  autosize($("textarea", $parent));

  // HTML CKEditor
  initHtmlTextarea($parent);

  // Range
  $("[type=range]", $parent).formRange();

  // Ajax upload
  const $ajaxUpload = new $x.AjaxUpload($("[data-ajax-upload]", $parent), {
    onUploadCompleted: function ($upload, $data) {
      $x.replaceHtml($data);
    },
  });

  $ajaxUpload.init();

  // File tree
  $("[data-file-tree]", $parent).formFileTree();

  // Form set
  $("[data-form-set]", $parent).formSet();

  $x.initMultipleSelectItems();

  return {
    ajaxUpload: $ajaxUpload,
  };
};

const $formDefaults = $x.initFormDefaults();

// Validation

$("[data-form]").formValidation({
  beforeSubmit: function () {
    $formDefaults.ajaxUpload.reset();
  },
  afterSubmit: function ($xhr, $form, $data) {
    if ($data.submit === "success") {
      if ($data.redirect) {
        checkRedirect($data);
      } else {
        $x.replaceHtml($data);

        if ($data.toaster) {
          $body.toaster("updateToaster", $data.toaster);
        }
      }
    }
  },
});

// Wizard

$("[data-form-wizard]").formWizard();

// CUSTOM OVERRIDE
// THIS $x.modal is overwritten because we when auto logout is activated the js does not check if th id=login-span-container is in content
// so i overwrite the whole $x.modal (Maybe there exists better ways to do this) to check if the response includes the id. if the repsponse includes the id
// we know the user is auto logged out and we reload the page so DJANGO handles redirects.

$x.modal = {
  $defaults: {
    beforeModalOpen: ($modal, $data) => {
      if ($data.submit === "error") {
        if ($data.toaster) {
          $("body").toaster("updateToaster", $data.toaster);
        }
      }
    },
    onModalOpen: ($modal, $data) => {
    },
    onModalClose: ($modal) => {
    },
  },

  open (url, $settings = {}) {
    const _this = this;
    const $modalWrapper = $("#modal_wrapper");

    // WORKAROUND: backdrop not removed when modal open other modal!
    $x.remove(document.querySelectorAll(".modal-backdrop"));
    $settings = { ..._this.$defaults, ...$settings };
    // modal parameter is used to distinguish between a regular ajax call
    // and a modal loaded ajax call which has a different base to work
    url = new URL(url, window.location.origin);
    url.searchParams.append("modal", "use_modal_base_template");

    $x.ajax.get(url, {
      success: function ($data) {
        if ($data.includes('id="login-span-container"')) {
          window.location.reload();
        }
        $modalWrapper.html($data);
        const $modal = $("[data-modal]", $modalWrapper).modal();

        $settings.beforeModalOpen($modal, $data);

        $modal.on("shown.bs.modal", () => {
          // Magic 🦄: Needed for popups in modal (e. g. CKEditor5 Link URL)
          $(document).off("focusin.modal");
          $settings.onModalOpen($modal, $data);
        });

        $modal.on("hidden.bs.modal", () => {
          $settings.onModalClose($modal);
          $modalWrapper.empty();
        });
      },
      error: ($error) => {
        let $data;
        const djangoDebug = document.querySelector("body").getAttribute("data-debug");

        // IF DEBUG=TRUE THE DEBUGGING PAGE NAMED technical_500.html of Django HAS FULL ERROR PAGE BUT WITH MANY
        // INLINE STYLING ETC WHICH BREAKS OUR MAIN BODY & DESIGN - THIS PAGE CANNOT BE REPLACED OR OVERWRITTEN BECAUSE
        // ITS AN INLINE VIEW-TEMPLATE. IT'S JUST FOR DEVELOPMENT SO WE JUST MAKE AN MODAL CODE WITH FIX TITLE + BODY
        // AND REPLACE ONLY THE ERROR MESSAGE WITH THE ERROR CONTENT OF THE RESPONSE. WHEN DEBUG=FALSE WE GET A COMPLETE
        // HTML PAGE WITH BASE_DIALOG SO IT WILL HAVE ALL MODAL RELATED DIVs ITSELF.
        if (djangoDebug === "True") {
          // THIS HTML IS COPIED FROM x_app/templates/error_dialog_base.html DEFAULT DIALOG_ERROR_BASE SO WE USE THE SAME BASE BUT WITH FIX CONTENT + TITLE
          $data = '<div aria-labelledby="modal-title" aria-hidden="true" class="modal fade" data-modal id="modal" role="dialog" tabindex="-1"> <div class="modal-dialog modal-dialog-centered modal-error-xl" role="document"> <div class="modal-content"> <div class="modal-header"> <h3 class="modal-title m-0" id="modal-title">DEBUG ERROR REPORT</h3> <a aria-label="Close" class="close" data-dismiss="modal" href="#"> <svg class="icon icon-dark"><use xlink:href="/static/img/core.sprite.symbol.svg#close"></use></svg> </a> </div> <div class="modal-body">[ERROR_LOG_REPORT_CONTENT]</div> </div> </div> </div>';
          $data = $data.replace("[ERROR_LOG_REPORT_CONTENT]", $error.response);
          $data = $data.replace("body * { padding:10px 20px; }", "");
          $data = $data.replace("body { font:small sans-serif; background-color:#fff; color:#000; }", "");
          $data = $data.replace("table { border:1px solid #ccc; border-collapse: collapse; width:100%; background:white; }", "");
          $data = $data.replace("    thead th {\n      padding:1px 6px 1px 3px; background:#fefefe; text-align:left;\n      font-weight:normal; font-size:11px; border:1px solid #ddd;\n    }", "");
        } else {
          $data = $error.response;
        }

        $modalWrapper.html($data);

        const $modal = $("[data-modal]", $modalWrapper).modal();

        $settings.beforeModalOpen($modal, $data);

        $modal.on("shown.bs.modal", () => {
          // Magic 🦄: Needed for popups in modal (e. g. CKEditor5 Link URL)
          $(document).off("focusin.modal");
          $settings.onModalOpen($modal, $data);
        });

        $modal.on("hidden.bs.modal", () => {
          $settings.onModalClose($modal);
          $modalWrapper.empty();
        });
      },
    });
  },
};


// #############################################################################
// DATA TABLE

const $dataTables = document.querySelectorAll("[data-table]");

$dataTables.forEach(function ($item) {
  $item.$datatable = new $x.DataTables($item, {
    api: function ($table, $api) {
      // API: https://datatables.net/reference/api/

      const $input = $table.querySelector("[data-multiple-select-item]");

      $api.on("draw", function () {
        $("[data-toggle=tooltip]", $table).tooltip();

        if ($input) {
          $input.$multipleSelectItem.reset();
        }
      });
    },
    customizeCSV: function (csv) {
      // For customization read https://datatables.net/reference/button/csv

      return csv;
    },
    rowGroupStartRender: function ($table, $rows, html) {
      return html;
    },
  });
});

// #############################################################################
// FULL CALENDAR

const $calendars = document.querySelectorAll("[data-calendar]");

$calendars.forEach(function ($item) {
  // gets all calendar data and then overrides the data url only if necessary
  const urlKwargs = new URLSearchParams(window.location.search);

  if (urlKwargs.size > 0) {
    const userId = urlKwargs.get("user_id");
    const contextMode = urlKwargs.get("context_mode");

    const calendarData = JSON.parse($item.getAttribute("data-calendar-extra-options"));
    calendarData.urls.data = calendarData.urls.data + `${userId}/${contextMode}/`;
    calendarData.urls.add.href = calendarData.urls.add.href + `${userId}/${contextMode}/`;

    $item.setAttribute("data-calendar-extra-options", JSON.stringify(calendarData));
  }

  $item.$calendar = new $x.FullCalendar($item, {
    initialView: "dayGridMonth",
    customButtons: {
      filter: {
        text: "Filter",
        click: function () {
          if (this.classList.contains("fc-filter-active")) {
            this.classList.remove("fc-filter-active");
          } else {
            this.classList.add("fc-filter-active");
          }
          const headerToolbar = $(".fc-header-toolbar");
          const filterEl = $("#calendar-filters")[0];
          changePositionOfFilter(filterEl, headerToolbar);
        },
      },
    },
  });
  $item.$calendar._checkButtonsVisibility();
});

// #############################################################################
// CHART JS

const $charts = document.querySelectorAll("[data-chartjs]");

$charts.forEach(function ($element) {
  const $chartJS = new $x.ChartJS($element);
  $chartJS.init();
});

// #############################################################################
// MODAL

$x.onModalCloseDefault = function () {
  // Don't remove this function because the fullcalendar needs it.
};

$x.beforeModalOpenDefault = function () {
  // Don't remove this function because the fullcalendar needs it.
};

$x.onModalOpenDefault = function ($modal) {
  $("[autofocus]", $modal).focus();
  $("[data-toggle=tooltip]", $modal).tooltip();
  dynamicFormContent($modal);
  tabMenu($modal);

  const $formDefaults = $x.initFormDefaults($modal);

  // Validation

  $("[data-form]", $modal).formValidation({
    beforeSubmit: function () {
      $formDefaults.ajaxUpload.reset();
    },
    afterSubmit: function ($xhr, $form, $data) {
      afterFormSubmitDefaults($data, $modal);
    },
  });

  // Wizard

  $("[data-form-wizard]", $modal).formWizard();
  const pw_set_email = document.getElementById("id_passwordsetemailsendform-contact_email");
  if (pw_set_email) pw_set_email.disabled = true;
  const useCustomerForCalendar = document.getElementById("id_setemployeecontextform-customer");
  if (useCustomerForCalendar) useCustomerForCalendar.disabled = true;
  $modal[0]?.getElementsByClassName("active")[0]?.click();
};

$x.delegateEvent.on(document, "click", "[data-modal-link]", function (e) {
  e.preventDefault();

  $x.modal.open(this.href, {
    onModalOpen: $x.onModalOpenDefault,
  });
});

// #############################################################################
// DOWNLOAD BLOB

$x.delegateEvent.on(document, "click", "[data-download]", function (e) {
  e.preventDefault();

  const $downloadBlob = new $x.DownloadBlob({
    onDownloadStarted: function ($data) {
      $body.toaster("updateToaster", $data.toaster);

      $dataTables.forEach(function ($item) {
        $item.$datatable.reload();
      });
    },
  });

  $downloadBlob.download(this.href);
});

// #############################################################################
// CLIPBOARD

$body.clipBoard({
  selector: "[data-clipboard]",
});

// #############################################################################
// TOASTER

$body.toaster({
  selector: "[data-toaster]",
});

// #############################################################################
// AUTO UPDATE HTML CONTENT

// TODO: Demo erstellen

$body.autoUpdateHtmlContent({
  selector: "[data-update-html-content]",
});


function afterFormSubmitDefaults ($data, $modal) {
  if ($data.submit === "success") {
    $modal.modal("hide");
    if ($data.modal_url) {
      // Open a new modal with the given url.
      $x.modal.open($data.modal_url, {
        onModalOpen: $x.onModalOpenDefault,
      });
    }

    if ($data.redirect) {
      checkRedirect($data);
    } else {
      $x.replaceHtml($data);
      if ($data.reload_box_ids) {
        reloadDashboardBoxes($data.reload_box_ids);
      }

      if ($data.toaster) {
        $body.toaster("updateToaster", $data.toaster);
      }

      $dataTables.forEach(function ($item) {
        $item.$datatable.reload();
      });

      $calendars.forEach(function ($item) {
        $item.$calendar.$api.refetchEvents();
      });
    }
  }
}


$x.delegateEvent.on(document, "click", "#reset-form-btn", (e) => {
  e.preventDefault();
  const datePickerDashboard = $("#time-slot-date-picker-shortcut")[0];
  if (datePickerDashboard) {
    datePickerDashboard.classList.remove("d-none");
  }
  const resetButton = document.getElementById("reset-form-btn");
  const value = resetButton.getAttribute("data-form");
  document.querySelector(`[data-form=${value}]`).remove();

  const datepickerDashboard = $("#time-slot-date-picker-shortcut")[0];
  if (datepickerDashboard) {
    datepickerDashboard.disabled = false;
    datepickerDashboard.value = null;
  }

  const datepicker = $("#time-slot-date-picker")[0];
  if (datepicker) {
    datepicker.disabled = false;
    datepicker.value = null;
  }

  const selectInput = $(".custom-select-form")[0];
  if (selectInput) {
    selectInput.selectedIndex = 0;
  }
  if (!datepicker) {
    if (selectInput) selectInput.disabled = false;
  }
});

function dynamicFormContent ($modal) {
  let customDate = false;

  $("#time-slot-date-picker", $modal).on("change", (event) => {
    const selectElement = $(".custom-select-form", $modal)[0];
    const datePicker = $("#time-slot-date-picker", $modal)[0];
    const minDate = new Date("January 1, 1994 00:00:00");
    const datePickerValue = new Date(datePicker.value);

    if (!isNaN(Date.parse(datePicker.value)) && datePickerValue > minDate) {
      selectElement.removeAttribute("disabled");
      customDate = true;
    }
  });

  $("#time-slot-date-picker-shortcut", $modal).on("change", function (event) {
    const minDate = new Date("January 1, 1994 00:00:00");
    const datePickerValue = new Date(this.value);

    if (datePickerValue > minDate) {
      const timeSlotType = document.querySelector("[data-time-slot-type]").getAttribute("data-time-slot-type");
      const url = `/scheduling/event/form/lifecycle/${timeSlotType}/${this.value}/`;
      replaceFormModalContent(url, $modal);
      this.disabled = true;
      this.classList.add("d-none");
    }
  });

  $(".custom-select-form", $modal).on("change", (event) => {
    const selectedOption = event.target.selectedOptions[0];
    let url = selectedOption.getAttribute("data-form-url");
    const datePicker = $("#time-slot-date-picker", $modal)[0];

    event.target.setAttribute("disabled", "true");
    datePicker?.setAttribute("disabled", "true");
    if (customDate) {
      if (url.includes("//")) {
        url = url.replace("//", "/");
      }
      url += $("#time-slot-date-picker", $modal)[0].value;
    }

    // Get the Source for the form, so it can be handled different in the calendar and dataview.
    const formSourceElement = $("[form_source]")[0]?.getAttribute("form_source");
    if (formSourceElement) {
      url += "?form_source=" + formSourceElement;
    }

    replaceFormModalContent(url, $modal);
  });
}

function tabMenu ($modal) {
  const tabs = $(".form-tab", $modal);

  for (const tab of tabs) {
    tab.addEventListener("keypress", function (e) {
      if (e.key === "Enter") {
        tab.click(); // Execute Click so the entry will be selected.
      }
    });

    tab.addEventListener("click", (event) => {
      for (const tab of $(".form-tab", $modal)) {
        if (tab !== event.target) {
          tab.classList.remove("active");
        } else {
          tab.classList.add("active");
        }
      }

      const url = event.target.getAttribute("data-tab-url");
      replaceFormModalContent(url, $modal);
    });
  }
}

function replaceFormModalContent (url, $modal) {
  $x.ajax.get(url, {
    beforeSend: ($xhr) => {
      $(".dynamic-form-content", $modal).html("");
      $("[dynamic-form-spinner]")[0].removeAttribute("hidden");
    },
    success: (data) => {
      if (data.includes('id="login-span-container"')) {
        window.location.reload();
        return null;
      }
      $(".dynamic-form-content", $modal).html(data);
      $("[dynamic-form-spinner]", $modal)[0].setAttribute("hidden", "true");

      $("form", $x.html($(".dynamic-form-content", $modal)[0], data)).formValidation({
        afterSubmit: function ($xhr, $form, $data) {
          afterFormSubmitDefaults($data, $modal);
        },
      });
      // Also Init the form-set listener.
      $("[data-form-set]", $modal).formSet();

      const changeEvent = new Event("change");

      // Fires the change event only when the field (where the change event listener is on) is not readonly to get the related options.
      const timeSlotClientSelectElement = $(".time-slot-client-select")[0];
      if (timeSlotClientSelectElement) {
        timeSlotClientSelectElement.dispatchEvent(changeEvent);
      }

      const timeSlotSelectElement = $(".time-slot-select")[0];
      if (timeSlotSelectElement) {
        timeSlotSelectElement.dispatchEvent(changeEvent);
      }

      const dynamicField = $("[data-field-hidden]")[0];
      if (dynamicField) {
        let isReadOnly = false;
        for (const child of dynamicField.childNodes) {
          if (child instanceof HTMLElement) {
            if (child.hasAttribute("readonly")) {
              if ($("#id_clientmissionform-accept_overlap_check")[0]?.checked) {
                isReadOnly = true;
              }
            }
          }
        }
        if (!isReadOnly) {
          dynamicField?.setAttribute("hidden", "true");
        }
      }

      // Remove label for Firefox browser...
      // Other Browsers handled in CSS app.scss line 186
      $(".remove-label").each(function () {
        $("label", $(this)[0].parentElement).addClass("d-none");
      });
    },
    error: ($xhr) => {
      $("[dynamic-form-spinner]", $modal)[0].setAttribute("hidden", "true");
    },
  });
}

$x.delegateEvent.on(document, "change", ".time-slot-client-select", function () {
  const clientID = this.value;
  const dateInput = $(".time-slot-date")[0];
  const timeSlotDate = dateInput?.value;
  const url = `/scheduling/meeting/points/${clientID}/${timeSlotDate}`;

  if (clientID === "" || timeSlotDate === "") return;

  changeOptionsForDropdown(url, ".time-slot-meeting-point-select");
});

$x.delegateEvent.on(document, "change", ".time-slot-date", function () {
  const clientInput = $(".time-slot-client-select")[0];
  const clientID = clientInput?.value;
  const timeSlotDate = this.value;
  const url = `/scheduling/meeting/points/${clientID}/${timeSlotDate}`;
  if (clientID === "" || timeSlotDate === "") return;

  changeOptionsForDropdown(url, ".time-slot-meeting-point-select");
});


$x.delegateEvent.on(document, "change", ".time-slot-select", function () {
  console.log(this.value);
  const timeSlotId = this.value;
  const timeSlotDate = $("input[id$='time_slot_date']")[0].value;
  const driveFormId = $body.find(".drive_form_id");
  const urlEnd = `/scheduling/locations/end/${timeSlotId}/${timeSlotDate}`;
  let urlStart = `/scheduling/locations/start/${timeSlotId}/${timeSlotDate}`;

  if (driveFormId) {
    urlStart = urlStart + `/${driveFormId.val()}`;
  }

  if (timeSlotId === "") return;

  changeOptionsForDropdown(urlStart, ".time-slot-drive-start-address");
  changeOptionsForDropdown(urlEnd, ".time-slot-drive-end-address");
});

$x.delegateEvent.on(document, "change", ".person-select-ajax", function (e) {
  const assistantId = this.value;
  const timeSlotDatePickerValue = $(".time_slot_date_picker").val();

  const timeSlotEndpoint = `/scheduling/timeslots/reload/${assistantId}/${timeSlotDatePickerValue}`;

  if (assistantId === "") return;

  changeOptionsForDropdown(timeSlotEndpoint, ".time_slot_picker");
});

$x.delegateEvent.on(document, "change", ".time_slot_date_picker", function (e) {
  const timeSlotDatePickerValue = this.value;
  const assistantId = $(".person-select-ajax").val();

  const timeSlotEndpoint = `/scheduling/timeslots/reload/${assistantId}/${timeSlotDatePickerValue}`;

  if (assistantId === "") return;

  changeOptionsForDropdown(timeSlotEndpoint, ".time_slot_picker");
});

function changeOptionsForDropdown (url, optionsToChangeByClass) {
  const defaultSelectValue = removeAllOptions(optionsToChangeByClass);
  if (defaultSelectValue === undefined) return;
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      for (const option of data) {
        const newOption = document.createElement("option");
        newOption.value = option[0];
        newOption.innerText = option[1];
        $(optionsToChangeByClass)[0].appendChild(newOption);
      }
      // Just add another setOption() function with id selector to set initial value and only show the related options.
      if (optionsToChangeByClass === ".time-slot-drive-start-address") setOption($("#id_driveform-start_address")[0], defaultSelectValue);
      if (optionsToChangeByClass === ".time-slot-drive-end-address") setOption($("#id_driveform-end_address")[0], defaultSelectValue);
      if (optionsToChangeByClass === ".time-slot-meeting-point-select") {
        setOption($("#id_clientmissionform-meeting_point")[0], defaultSelectValue);
        setOption($("#id_substituteform-meeting_point")[0], defaultSelectValue);
        setOption($("#id_serialclientmissionform-meeting_point")[0], defaultSelectValue);
      }
      if (optionsToChangeByClass === ".time_slot_picker") setOption($("#id_driveform-time_slot")[0], defaultSelectValue);
    });
}

function removeAllOptions (optionsToChangeByClass) {
  const selectInput = $(optionsToChangeByClass)[0];
  if (selectInput.options) {
    const defaultSelectValue = selectInput.value;
    for (let i = selectInput.options.length - 1; i > 0; i--) {
      selectInput.options[i].remove();
    }
    return defaultSelectValue;
  }
}

function setOption (selectElement, value) {
  if (selectElement !== undefined) {
    return [...selectElement.options].some((option, index) => {
      if (option.value === value) {
        selectElement.selectedIndex = index;
        return true;
      }
    });
  }
}

$x.delegateEvent.on(document, "change", "#id_cancelclientmissionform-employee_option", (event) => {
  const READY_TO_WORK = 1;
  if (parseInt(event.target.value) === READY_TO_WORK) {
    const div = document.createElement("div");
    div.setAttribute("id", "verification-warning");
    div.setAttribute("class", "d-flex align-items-center");
    $(div).addClass("mt-2 font-bolder");

    div.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" class='icon'  viewBox="0 0 24 24"><title>alert</title><path d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z" /></svg> <p class="ml-1 align-middle">${gettext("Achtung Vereinbarung ist erforderlich")}</p>`;
    event.target.parentElement.appendChild(div);
  } else {
    $("#verification-warning")[0].remove();
  }
});

$x.delegateEvent.on(document, "load", "[data-form-set-add]", function () {
  if (this.classList.contains("disabled")) {
    // set the opacity to "0", to hide the disabled "Add" Button from a form-set,
    // because when the will disabled, the data shouldn't be changed.
    this.style.opacity = "0";
  }
});

function changeFilterVisibility (filterEl) {
  if (filterEl.getAttribute("aria-expanded") === "true") {
    filterEl.setAttribute("aria-expanded", "false");
    filterEl.classList.remove("p-1");
    filterEl.classList.remove("border");
  } else {
    filterEl.setAttribute("aria-expanded", "true");
    filterEl.classList.add("p-1");
    filterEl.classList.add("border");
  }
}

$(document).ready(function () {
  $(".select2-container--default").css({ width: "" });
});

function changePositionOfFilter (filterEl, insertAfterElement) {
  insertAfterElement[0].insertAdjacentElement("afterend", filterEl);
  changeFilterVisibility(filterEl);
}

const filter_persons = $(".filter_persons_class");
const filter_service_type = $(".filter_service_types_class");

filter_persons?.change(function () {
  const calendar = document.querySelector("[data-calendar]").$calendar;
  calendar.addExtraParameterResources("filter_" + "persons", filter_persons.val());
  calendar._updateEvents(calendar.$api.view.type);
});

filter_service_type?.change(function () {
  const calendar = document.querySelector("[data-calendar]").$calendar;
  calendar.addExtraParameter("filter_" + "service_type", filter_service_type.val());
  calendar._updateEvents(calendar.$api.view.type);
});

// DASHBOARD
// -----------------------------------------------------------------------------

function reloadDashboardBoxes ($boxIds) {
  for (let i = 0; i < $boxIds.length; i++) {
    const el = document.getElementById($boxIds[i]);

    if (!el) {
      $boxIds.splice(i, 1);
    }
  }

  $boxIds.forEach(($boxId) => {
    fetch(`/dashboard/singlebox/${$boxId}`)
      .then(response => response.text())
      .then(result => {
        if (result.includes('id="login-span-container"')) {
          window.location.reload();
          return null;
        }
        const parser = new DOMParser();
        const elToReload = document.getElementById(`${$boxId}`);
        if (elToReload) {
          const parsedHTML = parser.parseFromString(result, "text/html");
          const firstDiv = parsedHTML.querySelector("div:first-child");
          elToReload.parentElement.replaceChild(firstDiv, elToReload);
        }
      });
  });
}

const roleChangerBtn = document.getElementById("role_changer");

roleChangerBtn?.addEventListener("click", function () {
  toggleDropdown("role_box", "btn-chevron", this);
});

const billingConfigChanger = document.getElementById("billing_config_changer");

billingConfigChanger?.addEventListener("click", function () {
  toggleDropdown("billing_config_changer_box", "btn-chevron-billing_config_changer", this);
});

function toggleDropdown (elId, chevronId, btn) {
  const el = document.getElementById(elId);
  const chevron = document.getElementById(chevronId);
  if (!el) return;
  if (el.getAttribute("aria-expanded") === "true") {
    el.setAttribute("aria-expanded", "false");
    btn.setAttribute("aria-expanded", "false");
    chevron.classList.add("rotate-0");
    chevron.classList.remove("rotate-180");
  } else {
    el.setAttribute("aria-expanded", "true");
    btn.setAttribute("aria-expanded", "true");
    chevron.classList.remove("rotate-0");
    chevron.classList.add("rotate-180");
  }
}

// CHANGE PERSONS BY EXPORT
// Changes the person in the dropdown to the related persons that hava a DRIVE TIMESLOT in this time.
$(".person_related").each(function () {
  this.addEventListener("change", function () {
    const url = addGetParameters();
    $x.ajax.get("/exports/persons" + url, {
      success: function (result) {
        result = JSON.parse(result);
        $(`.${result.field}`).each(function () {
          this.innerHTML = "";
          // appends an not valid item as default.
          this.appendChild(createOption());
          result.data.forEach(item => {
            // appends all persons that are returned from the endpoint.
            this.appendChild(createOption(item[1], item[0]));
          });
        });
      },
    });
  });
});

function createOption (text = "---------", value = "") {
  const option = document.createElement("option");
  option.value = value;
  option.text = text;
  return option;
}

function addGetParameters () {
  let url = "";
  $(".person_related").each(function () {
    url += `/${this.value}`;
  });

  return url;
}

$x.ajax.fetch = function (method, url, $settings) {
  const _this = this;
  const $xhr = new XMLHttpRequest();

  $settings = { ..._this.$defaults, ...$settings };

  $xhr.open(method, url);
  $settings.beforeSend($xhr);

  $xhr.onreadystatechange = () => {
    if ($xhr.readyState === XMLHttpRequest.DONE) {
      const status = $xhr.status;

      if (status === 0 || (status >= 200 && status < 400)) {
        // The request has been completed successfully

        if ($settings.success) {
          if ($settings.dataType === "json") {
            $settings.success(JSON.parse($xhr.responseText), $xhr);
          } else {
            if ($xhr.responseText.includes("login-span-container")) {
              window.location.reload();
            } else {
              $settings.success($xhr.responseText, $xhr);
            }
          }
        }
      } else {
        $settings.error($xhr);
      }
    }
  };

  // The header X-Requested-With allows server side frameworks, such as Django,
  // to identify Ajax requests. It's optional, but can be useful.
  $xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

  if ($settings.responseType) {
    $xhr.responseType = $settings.responseType;
  }

  $xhr.send(_this._getData($settings));

  return $xhr;
};
